<template>
  <div :class="{ 'is-active': active }" class="modal">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Disclaimer</p>
      </header>
      <section class="modal-card-body">
        <div class="content">
          <p>By signing up you agree that anything provided inside the server is not investment advice. I am not a
            licensed financial advisor. Information found in the server is for entertainment or education purposes
            only and should not be taken as personal investment advice.</p>
          <p>RektProof PA is not responsible for any losses that occur with the information provided. All trading
            strategies are used at your own risk.</p>
          <p>The content is subject to change at any time without notice and is provided for the sole purposes of
            education and assistance in making independent investment decisions.</p>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-dark" @click="closeModal()">Understood</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DisclaimerModal',
  props: ['active'],
  methods: {
    closeModal() {
      this.$emit('close')
    }
  }
};
</script>

<style lang="scss" scoped>
.buttons {
  width: 100%;
}
.button.is-ghost {
  color: black;
  &:hover {
    color: black;
  }
}
.brand-logo {
  height: 40px;
  max-height: none;
}
</style>
