<template>
  <div class="main">
    <Navbar />
    <section class="main-hero">
      <p class="main-title">Materials</p>

<!--      <div>-->
<!--        <agile>-->
<!--          <div class="slide" v-for="n in 6" :key="n" :class="`slide&#45;&#45;${n}`">-->
<!--            <h3>{{ n }}</h3>-->
<!--          </div>-->
<!--          <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>-->
<!--          <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>-->
<!--        </agile>-->
<!--        <Carousel>-->
<!--          <Slide>-->
<!--            <img src="@/assets/materials-education-sample.png" alt="Education Sample" />-->
<!--          </Slide>-->
<!--          <Slide>-->
<!--            <img src="@/assets/materials-education-sample.png" alt="Education Sample" />-->
<!--          </Slide>-->
<!--          <Slide>-->
<!--            <img src="@/assets/materials-education-sample.png" alt="Education Sample" />-->
<!--          </Slide>-->
<!--        </Carousel>-->
<!--        <VueSlickCarousel :options="slickOptions" :arrows="true" :dots="true">-->
<!--          <div class="has-text-black">1</div>-->
<!--          <div class="has-text-black">2</div>-->
<!--          <div class="has-text-black">3</div>-->
<!--          <div class="has-text-black">4</div>-->
<!--                  <img src="@/assets/materials-education-sample.png" alt="Education Sample" />-->
<!--                  <img src="@/assets/materials-education-sample.png" alt="Education Sample" />-->
<!--        </VueSlickCarousel>-->
<!--      </div>-->
      <div class="materials-container has-text-white">
        <div class="materials-menu is-flex is-justify-content-space-between">
          <p @click="selectType('Education')" :class="{ 'selected': isEducationSelected }">Education</p>
          <p @click="selectType('Analysis')" :class="{ 'selected': isAnalysisSelected }">Analysis</p>
          <p @click="selectType('Tools')" :class="{ 'selected': isToolsSelected }">Tools</p>
        </div>
        <div v-if="isEducationSelected">
          <p>
            Unlock the potential of the financial markets with a comprehensive trading education program. The program includes a variety of lessons, covering everything from the basics of trading to advanced strategies and concepts and risk management.
          </p>
          <br>
          <p>
            Rektproof have years of experience in the industry and will personally guide you through the learning process every step of the way. Whether you're a beginner or an experienced trader, his program and mentorship will help you take your skills to the next level.
          </p>
          <br>
          <p>
            Sign up today and start your financial journey now!
          </p>
          <br>
          <p @click="activateLightbox" class="is-underlined lightbox-link">View sample lesson</p>
        </div>
        <div v-if="isAnalysisSelected">
          <p>
            Stay ahead of the game with live market analysis and insight service. Rektproof provides in-depth analysis of global markets focusing on cryptocurrencies.
          </p>
          <br>
          <p>
            We offer real-time alerts, daily market updates, and a community to share trade ideas with. Our analysis is based on price action and backed by years of experience in the industry.
          </p>
          <br>
          <p>
            Whether you're a professional trader or an individual investor, our market analysis and insight will give you the knowledge you need to make informed decisions and show you how to apply the strategies and concepts learned from the education program to make your own analysis.
          </p>
          <br>
          <p>
            Sign up today and learn how to develop your edge in the market!
          </p>
          <br>
          <p @click="activateLightbox" class="is-underlined lightbox-link">View sample analysis</p>
        </div>
        <div v-if="isToolsSelected">
          <p>
            Join a community of like-minded traders and take your trading to the next level with our comprehensive suite of tools and resources.
          </p>
          <br>
          <p>
            Our offering includes years of price action and setup examples, a trade journal to track your progress, a PnL and risk-reward calculator, educational content, trading competitions, and access to a community to share your journey with.
          </p>
          <br>
          <p>
            Learn from real-life examples of price action, improve your decision-making with our PnL and risk-reward calculator, and keep track of your trades and performance with our trade journal. Challenge yourself with our trading competitions and gain insights from our community of experienced traders.
          </p>
          <br>
          <p>
            Sign up now and gain access to all the tools you need to conquer the markets while being part of a supportive community!
          </p>
          <br>
          <p @click="activateLightbox" class="is-underlined lightbox-link">View tools sample</p>
        </div>
      </div>
    </section>
    <div class="main-footer">
      <a @click="disclaimer = true">disclaimer</a>
    </div>
    <MaterialLightbox :active="lightbox" @close="lightbox = false">
      <!-- Slider container -->
      <div v-if="isEducationSelected" class="slider">
        <!-- slide 1 -->
        <div class="slide">
            <img src="@/assets/materials_education_1.png" alt="Education Sample 1" />
        </div>

        <!-- slide 2 -->
        <div class="slide">
          <img src="@/assets/materials_education_2.png" alt="Education Sample 2" />
        </div>

        <!-- slide 3 -->
        <div class="slide">
          <img src="@/assets/materials_education_3.png" alt="Education Sample 3" />
        </div>

        <!-- Control buttons -->
<!--        <button @click="nextSlide" class="btn btn-next"> > </button>-->
<!--        <button @click="prevSlide" class="btn btn-prev"> < </button>-->
      </div>
      <!-- Slider container -->
      <div v-if="isAnalysisSelected" class="slider">
        <!-- slide 1 -->
        <div class="slide">
          <img src="@/assets/materials_analysis_1.png" alt="Analysis Sample 1" />
        </div>

        <!-- slide 2 -->
        <div class="slide">
          <img src="@/assets/materials_analysis_2.png" alt="Analysis Sample 2" />
        </div>

        <!-- slide 3 -->
        <div class="slide">
          <img src="@/assets/materials_analysis_3.png" alt="Analysis Sample 3" />
        </div>

        <!-- Control buttons -->
<!--        <button @click="nextSlide" class="btn btn-next"> > </button>-->
<!--        <button @click="prevSlide" class="btn btn-prev"> < </button>-->
      </div>
      <!-- Slider container -->
      <div v-if="isToolsSelected" class="slider">
        <!-- slide 1 -->
        <div class="slide">
          <img src="@/assets/materials_tools_1.png" alt="Tools Sample 1" />
        </div>

        <!-- slide 2 -->
        <div class="slide">
          <img src="@/assets/materials_tools_2.png" alt="Tools Sample 2" />
        </div>

        <!-- Control buttons -->
<!--        <button @click="nextSlide" class="btn btn-next"> > </button>-->
<!--        <button @click="prevSlide" class="btn btn-prev"> < </button>-->
      </div>
      <div class="dots">
        <div v-for="(slide, i) in slides" :key="i" @click="changeSlide(i)" class="dot" :class="{ active: i === curSlide }" />
      </div>
    </MaterialLightbox>
    <DisclaimerModal :active="disclaimer" @close="disclaimer = false" />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import DisclaimerModal from "@/components/DisclaimerModal";
import Mobilemenu from "@/components/Mobilemenu.vue";
import MaterialLightbox from "@/components/MaterialLightbox";

export default {
  name: "Home",
  components: {
    Navbar,
    DisclaimerModal,
    MaterialLightbox,
    Mobilemenu,
  },
  data() {
    return {
      disclaimer: false,
      lightbox: false,
      selectedMaterialType: "Education",
      slides: [],
      curSlide: 0,
      maxSlide: 0,
    };
  },
  mounted() {
    this.initSlider()
  },
  computed: {
    isEducationSelected() {
      return this.selectedMaterialType === "Education";
    },
    isAnalysisSelected() {
      return this.selectedMaterialType === "Analysis";
    },
    isToolsSelected() {
      return this.selectedMaterialType === "Tools";
    },
  },
  methods: {
    initSlider() {
      this.curSlide = 0
      // Select all slides
      this.slides = window.document.querySelectorAll(".slide");
      // loop through slides and set each slides translateX
      this.slides.forEach((slide, index) => {
        slide.style.transform = `translateX(${index * 101}%)`;
      });
      // maximum number of slides
      this.maxSlide = this.slides.length - 1;
    },
    selectType(type) {
      this.selectedMaterialType = type
      // this.initSlider()
    },
    changeSlide(i) {
      this.curSlide = i
      //   move slide by 100%
      this.slides.forEach((slide, index) => {
        slide.style.transform = `translateX(${101 * (index - this.curSlide)}%)`;
      });
    },
    activateLightbox() {
      this.lightbox = true
      this.initSlider()
    },
    prevSlide() {
      // check if current slide is the first and reset current slide to last
      if (this.curSlide === 0) {
        this.curSlide = this.maxSlide;
      } else {
        this.curSlide--;
      }

      //   move slide by 100%
      this.slides.forEach((slide, index) => {
        slide.style.transform = `translateX(${101 * (index - this.curSlide)}%)`;
      });
    },
    nextSlide() {
      // check if current slide is the last and reset current slide
      if (this.curSlide === this.maxSlide) {
        this.curSlide = 0;
      } else {
        this.curSlide++;
      }

      //   move slide by -100%
      this.slides.forEach((slide, index) => {
        slide.style.transform = `translateX(${101 * (index - this.curSlide)}%)`;
      });
    }
  },
};
</script>

<style scoped lang="scss">
.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 76px);
}
.main {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  font-family: "Work Sans", sans-serif;
  background: linear-gradient(
    180deg,
    var(--background-color-gradient-secondary) 0%,
    var(--background-color-gradient-tertiary) 100%
  );
  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-image: url("~@/assets/ocean-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}
.main-hero {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .main-title {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    letter-spacing: 0.1em;
    color: white;
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .materials-container {
    width: 456px;
    .materials-menu {
      p {
        &.selected {
          text-decoration: underline;
          color: white;
        }
      }
      cursor: pointer;
      margin-top: 67px;
      margin-bottom: 44px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.2);
    }
    .lightbox-link {
      cursor: pointer;
    }
  }
}
.main-footer {
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
  z-index: 2;
  a {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.1em;
    text-decoration-line: underline;
    color: #ffffff;
  }
}

//slider
.slider {
  width: 100%;
  //height: 100%;
  max-width: 1024px;
  //height: 410px;
  height: 80vh;
  position: relative;
  overflow: hidden; /* <===  */
  border-radius: 15px;
  //max-width: 100%;
  //min-height: 100%;
}

.slide {
  width: 100%;
  //height: 100%;
  max-width: 1024px;
  //height: 410px;
  height: calc(80vh - 30px);
  position: absolute;
  transition: all 0.5s;
  //max-width: 100%;
  //min-height: 100%;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

//.btn {
//  position: absolute;
//  width: 40px;
//  height: 40px;
//  padding: 10px;
//  border: none;
//  border-radius: 50%;
//  z-index: 10;
//  cursor: pointer;
//  background-color: #fff;
//  font-size: 18px;
//}
//.btn:active {
//  transform: scale(1.1);
//}
//.btn-prev {
//  top: 45%;
//  left: 2%;
//}
//
//.btn-next {
//  top: 45%;
//  right: 2%;
//}
.dots {
  display: flex;
  justify-content: center;
  gap: 13px;
  margin-top: 30px;
  .dot {
    &.active {
      background: white;
    }
    cursor: pointer;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    border: 1px solid white;
  }
}
</style>
